.title {
    text-align: center;
    color: goldenrod;
    font-family: 'Lemon', cursive;
    font-size: 500%;
}

.logo {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
    height: auto;
}

body {
    background-color: black;
}

.fa-facebook {
    color: goldenrod;
    padding-right: 1%;
    padding-bottom: 10%;
}

.fa-instagram {
    color: goldenrod;
    padding-left: 1%;
    padding-bottom: 10%;
}

.container {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
    height: auto;
    text-align: center;
}

@media screen and (max-width: 600px) {
    .title {
      font-size: 30px; 
    }
}